import 'react-toastify/dist/ReactToastify.css';

import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Footer, Header } from '@/fsd/shared/ui';
import { Divider } from '@mui/material';

function App() {
  return (
    <>
      {/* <Counter /> */}
      <Header />
      <div style={{ paddingBottom: '0.5rem' }}></div>
      {/* <Breadcrumbs /> */}
      <Outlet />
      <Divider />
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
