import { useClickMenuItem } from '@/fsd/shared/ui/Header/impl/hooks/useClickMenuItem';
import {
  AgeItemType,
  AgeItemTypeMenu,
} from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import { CaretDownIcon } from '@radix-ui/react-icons';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { RadixListItem } from './RadixListItem';

interface ItemMenuAndSubItemsProps {
  item: AgeItemType;
  subItems: AgeItemType[];
}

/**
 * Елемент меню та його підменю
 * @param props
 * @returns
 */
export function ItemMenuAndSubItems(props: ItemMenuAndSubItemsProps) {
  const { item } = props;

  return (
    <>
      <NavigationMenu.Item>
        <NavigationMenu.Trigger className='NavigationMenuTrigger'>
          {item.name} <CaretDownIcon className='CaretDown' aria-hidden />
        </NavigationMenu.Trigger>
        <_SubItems {...props} />
      </NavigationMenu.Item>
    </>
  );
}

const _SubItems = (props: ItemMenuAndSubItemsProps) => {
  const { subItems } = props;
  const { onClickMenuItem } = useClickMenuItem();

  return (
    <NavigationMenu.Content className='NavigationMenuContent'>
      <ul className='List two'>
        {subItems.map((el, _idx) => {
          const _el = el as AgeItemTypeMenu;

          return (
            <RadixListItem
              key={_idx}
              title={el.name}
              // href={_item.link}
              onClick={() => onClickMenuItem(_el)}
              className='NavigationMenuLink'
            />
          );
        })}
      </ul>
    </NavigationMenu.Content>
  );
};
