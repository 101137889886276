import './Right.css';

export const Right = () => {
    return (
        <div className='contacts__right'>
            <iframe
                src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10147.954979558082!2d30.777809253442385!3d50.51577623107483!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4d9857c74323d%3A0x9ef0fe46478a7ebb!2sDanceex!5e0!3m2!1sen!2sus!4v1704886914114!5m2!1sen!2sus'
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                // class='google-map'
            ></iframe>
        </div>
    );
};
