import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartSchema, ItemByITyped } from '../types/CartSchema';

const initialState: CartSchema = {
  itemsByCount: [],
};

export const cartSlice = createSlice({
  name: 'cartSlice',
  initialState,
  reducers: {
    addProductById: (state, action: PayloadAction<ItemByITyped>) => {
      console.log(state.itemsByCount);
      console.log(action.payload);
      const index = getIndex(state, action);

      if (index > -1) {
        state.itemsByCount[index].qty++;
      } else {
        state.itemsByCount.push({
          qty: 1,
          item: { ...action.payload },
        });
      }
    },
    reduceProduct: (state, action: PayloadAction<ItemByITyped>) => {
      const index = getIndex(state, action);

      if (index > -1) {
        if (state.itemsByCount[index].qty > 0) {
          state.itemsByCount[index].qty--;
        }
      }
    },
    deleteProduct: (state, action: PayloadAction<ItemByITyped>) => {
      const index = getIndex(state, action);

      if (index > -1) {
        state.itemsByCount.splice(index, 1);
      }
    },
    emptyCart: (state) => {
      state.itemsByCount = [];
    },
  },
});

export const { actions: cartSliceActions, reducer: cartSliceReducer } =
  cartSlice;

function getIndex(state: CartSchema, action: PayloadAction<ItemByITyped>) {
  const { product_id, offer_id, color } = action.payload;

  return state.itemsByCount.findIndex(
    (i) =>
      i.item.product_id === product_id &&
      i.item.offer_id === offer_id &&
      i.item.color === color
  );
}
