// import './fonts.css?inline';  // New in Vite 4. Fix double loading css.
import './fonts.css';
import './styles/css/index.scss';
import '@/fsd/shared/i18n/i18n.ts';

import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { BuildRoutes } from '@/routes/Routes';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import { MuiThemeProvider } from './fsd/app/providers/MuiThemeProvider';
import { ReduxStoreByOfficialDocProvider } from './fsd/app/providers/ReduxStoreByOfficialDoc';

const Fallback = () => (
    <div
        style={{
            minHeight: '100lvh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}
    >
        <CircularProgress />
    </div>
);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ReduxStoreByOfficialDocProvider>
            <MuiThemeProvider>
                <Suspense fallback={<Fallback />}>
                    <RouterProvider router={BuildRoutes} />
                </Suspense>
            </MuiThemeProvider>
        </ReduxStoreByOfficialDocProvider>
    </React.StrictMode>
);
