import { IFiltersRTKQ } from '../../types/filtersRTKQType';
import { getIdsStr } from './RTKQProductsListApi';

export function buildKeyValueQueryParamsByFiltersRTKQ(
    filtersRTKQ: IFiltersRTKQ
) {
    const arrEntries = Object.entries(filtersRTKQ);

    const keyValue = arrEntries.map((_) => {
        const key = _[0];
        const values = _[1].values;

        return { key, value: getIdsStr(values) };
    });

    const searchParams: URLSearchParams = new URLSearchParams();

    keyValue.forEach((item) => {
        if (item.value) {
            searchParams.append(item.key, item.value);
        }
    });

    return searchParams;
}

// export function buildKeyValueQueryParamsByFiltersRTKQ(
//     filtersRTKQ: IFiltersRTKQ
// ) {
//     // const result: Record<string, any> = Object.keys(filtersRTKQ)
// const result = Object.keys(filtersRTKQ)
//     .map((key) => [key, getIdsStr(filtersRTKQ[key].values)])
//     .filter((arr) => arr[1]);

//     return result;
// }

// export function buildKeyValueQueryParamsByFiltersRTKQ(
//     filtersRTKQ: IFiltersRTKQ
// ) {
//     const arrEntries = Object.entries(filtersRTKQ);

//     const result: Iterable<readonly [PropertyKey, any]> | <PropertyKey,string>[][] = [];

//     arrEntries.forEach((_) => {
//         const key = _[0];
//         let values = _[1].values;

//         const value = getIdsStr(values);
//         if (value.length > 0) {
//             result.push([key, value]);
//         }
//     });

//     return Object.fromEntries(result);
// }
