import { TBlock } from './components/Item/ui/types';

export const dataItems: TBlock[] = [
    {
        name: 'Інформація',
        links: [
            {
                name: 'Магазини',
                link: '/store-locator',
            },
            {
                name: 'Стати представником',
                link: '/franchise',
            },
        ],
    },
    {
        name: 'Підтримка',
        links: [
            {
                name: 'Доставка та оплата',
                link: '/store-locator',
            },
            {
                name: `Зв'язатися з нами`,
                link: '/franchise',
            },
        ],
    },
];
