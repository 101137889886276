import './Card.css';

import { TypeProductComponent } from 'src/fsd/shared/types';

import { Description, Image } from '../impl';

export const Card = ({ product }: TypeProductComponent) => {
  return (
    <div className='ProductCard '>
      <Image product={product} />
      <Description product={product} />
    </div>
  );
};
