import { useState } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

interface T {
  label: string;
  list: string[];
}

export const MockDropdown = ({ label, list }: T) => {
  const [value, setValue] = useState('')

  return <>
    <FormControl fullWidth sx={{ m: 0 }}>
      <InputLabel id={label + '-label'}>{label}</InputLabel>
      <Select
        labelId={label + '-label'}
        id={'id' + label + '-label'}
        value={value}
        label={label}
        onChange={(e) => setValue(e.target.value)}
      >
        {list.map((v, i) => <MenuItem key={i} value={v}>{v}</MenuItem>)}
      </Select>
    </FormControl>
  </>
}

