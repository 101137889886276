type T = {
    num: number;
    currency: string;
}

export function numCurrencyFormat({ num, currency }: T) {
    const f = Intl.NumberFormat('uk-UA', {
        currency,
        style: 'currency',
        maximumFractionDigits: 0,
    });

    const price = f.format(num)

    return price
}