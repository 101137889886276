import './styles/styles.css';

import { useGetMainMenuQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';

import { RenderMenu } from './widgets/RenderMenu';

export const RadixDesktopNavigationMenu = () => {
  const {
    isLoading,
    isError,
    // isSuccess,
    data: requestedFiltersRTKQ,
  } = useGetMainMenuQuery(null);

  if (isLoading) {
    return <span>Завантаження меню...</span>;
  }

  if (isError) {
    return <span style={{ color: 'red' }}>Помилка завантаження меню</span>;
  }

  if (!requestedFiltersRTKQ) {
    return (
      <span style={{ color: 'red' }}>
        Не вдалось отримати дані для меню із сервера
      </span>
    );
  }

  return <RenderMenu requestedFiltersRTKQ={requestedFiltersRTKQ} />;
};

export default RadixDesktopNavigationMenu;
