import './Footer.css';

import { Columns, Social } from '../impl';

export const Footer = () => (
    <section
        aria-label='Website footer content'
        className='Footer-Content Footer-Content_isVisible'
    >
        <div className='ContentWrapper '>
            <div className='Footer-Links'>
                <Columns />
                <Social />
            </div>
        </div>
    </section>
);
