import { ReactNode, useMemo } from 'react';

import { lightTheme } from '@/data/themes/light';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface MuiThemeProviderProps {
    children: ReactNode;
}

export const MuiThemeProvider = (props: MuiThemeProviderProps) => {
    const { children } = props;
    const theme = useMemo(() => createTheme(lightTheme), []);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
