const CommonStyle = {
  components: {
    // MuiButton: {
    // styleOverrides: {
    //   root: {
    //     borderRadius: '15px',
    //     paddingTop: '12px',
    //     paddingBottom: '12px',
    //   },
    // },
    // variants: [
    //   {
    //     props: { variant: 'authForm' },
    //     style: {
    //       borderRadius: '15px',
    //       paddingTop: '12px',
    //       paddingBottom: '12px',
    //     }
    //   }
    // ]
    // },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: '1rem',
          borderRadius: 1,
          mt: 2,
        },
        // root: unstable_sx({
        // }),
      },
    },
    // MuiListItemIcon: {
    //   styleOverrides: {
    //     root: {},
    //   },
    // },
  },
  typography: {
    fontFamily: ["Montserrat", "sans-serif"].join(","),
  },
};

export default CommonStyle;
