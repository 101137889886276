import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';

import type { AppDispatch, RootState, store } from './config/store';

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<typeof store>();

export const useAppDispatchWithTypes: () => AppDispatch = useDispatch;
export const useAppSelectorWithTypes: TypedUseSelectorHook<RootState> =
  useSelector;
// export const useAppStoreWithTypes: () => typeof store = useStore;
