import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import {
  useAppDispatch,
  useAppSelectorWithTypes,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions, getItemsByCount } from '@/fsd/entities/cart';
import { cartProductsSelectors } from '@/fsd/entities/cartProducts/model/selectors/cartProductsSelectors';
import {
  useGetProductsByIdsCartQuery,
  useGetProductsByIdsQuery,
} from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { TypeOffer } from '@/fsd/shared/types';
import { ActionButtons } from '@/fsd/shared/ui/Cart/actions/ActionButtons';
import {
  getOfferByProdIdInCart,
  getProductByProdIdInCart,
} from '@/fsd/shared/ui/Cart/cartUtils';
import { EmptyCart } from '@/fsd/shared/ui/Cart/EmptyCart';
import { useGetCartSum } from '@/fsd/shared/ui/Cart/hooks/useGetCartSum';
import { CustomCartItemProps } from '@/fsd/shared/ui/Cart/types/CustomCartItemProps';
import { TheSameGood } from '@/fsd/shared/ui/commons/TheSameGood';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';

import cls from './CartPageCustom.module.scss';

export const CartPageCustom = () => {
  const cartRaw = useAppSelectorWithTypes(getItemsByCount);

  if (cartRaw.length === 0) {
    return <EmptyCart />;
  }

  return <CheckRequirements />;
};

function CheckRequirements() {
  const cartRaw = useAppSelectorWithTypes(getItemsByCount);

  const ids = cartRaw.map((_) => _.item.product_id);
  const listIds = ids.join(', ');

  const { currentData, isLoading, error } = useGetProductsByIdsCartQuery(ids);

  if (isLoading) {
    return (
      <div className='ContentWrapper' style={{ fontSize: '36px' }}>
        Завантаження ids {listIds}
      </div>
    );
  }

  if (error) {
    return (
      <div
        className='ContentWrapper'
        style={{ fontSize: '36px', color: 'red' }}
      >
        Помилка завантаження ids {listIds}
      </div>
    );
  }

  if (!currentData || currentData.data.length === 0) {
    return (
      <div
        className='ContentWrapper'
        style={{ fontSize: '36px', color: 'red' }}
      >
        Товари ids {listIds} не знайдені
      </div>
    );
  }

  return <RenderRows />;
}

function RenderRows() {
  const cartRaw = useAppSelectorWithTypes(getItemsByCount);

  const sum = useGetCartSum();
  const dispatch = useAppDispatch();

  const emptyCart = () => {
    dispatch(cartSliceActions.emptyCart());
  };

  const useStyle = {
    Button: {
      '&:hover': {
        boxShadow: 'lightcoral !important',
        color: 'red',
      },
      '&:active': {
        boxShadow: 'none !important',
        backgroundColor: 'lightcoral !important',
        color: 'unset',
      },
    },
  };

  return (
    <div className='ContentWrapper'>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'end',
        }}
      >
        <IconButton
          aria-label='Remove Shopping Cart'
          onClick={emptyCart}
          sx={useStyle.Button}
        >
          <RemoveShoppingCartIcon />
        </IconButton>
      </div>
      {cartRaw.map((cartProd, idx) => (
        <CustomCartItem key={idx} idx={idx} cartProd={cartProd} />
      ))}
      <br />
      <h4>Сума {sum} ₴</h4>
      <SendCart />
    </div>
  );
}

function CustomCartItem({ idx, cartProd }: CustomCartItemProps) {
  const cartProducts = useAppSelectorWithTypes(cartProductsSelectors.products);

  const product = getProductByProdIdInCart(cartProducts, cartProd);

  if (!product) {
    return (
      <li>
        <span className={cls['Product-Row']}>
          <span>{idx + 1}</span>
          На сервері не знайдено товар з кодом {cartProd.item.product_id}.
          Очевидно такий товар раніше був на сервері, але зараз немає.
        </span>
      </li>
    );
  }

  const offer = getOfferByProdIdInCart(product, cartProd);
  const color = product?.danceex_options.color || '';

  return (
    <li>
      <span className={cls['Product-Row']}>
        <span>{idx + 1}</span>

        <Link to={`/product/${product.id}`}>
          <span
            style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                maxWidth: '112px',
                flexShrink: '0',
              }}
            >
              <TheSameGood product={product} />
            </div>
            {/* {product?.name} (код: {product?.id}) */}
            {product?.name}
          </span>
        </Link>

        <span>{color.length > 0 && <>{color}</>}</span>
        <span>
          {cartProd.item.offer_id && (
            <>
              <ListOfferProps offer={offer} />
              {/* (код: {cartProd.item.offer_id}) */}
            </>
          )}
        </span>
        <span>Ціна {offer?.price} ₴</span>
        <ActionButtons cartProd={cartProd} />
      </span>
    </li>
  );
}

function getObjListOfferProps({ offer }: { offer: TypeOffer | undefined }) {
  return offer?.properties.map((prop) => prop.name + ' ' + prop.value);
}

function ListOfferProps({ offer }: { offer: TypeOffer | undefined }) {
  return offer?.properties.map((prop, _idx) => (
    <span key={_idx}>
      {prop.name} {prop.value}
    </span>
  ));
}

// function ActionButtons({ cartProd }: { cartProd: ItemsByCountType }) {
//   const dispatch = useAppDispatch();

//   const item = {
//     product_id: cartProd.item.product_id,
//     offer_id: cartProd.item.offer_id,
//     color: cartProd.item.color,
//   };

//   const add = () => {
//     dispatch(cartSliceActions.addProductById({ ...item }));
//   };
//   const remove = () => {
//     dispatch(cartSliceActions.reduceProduct({ ...item }));
//   };
//   const btnDelete = () => {
//     dispatch(cartSliceActions.deleteProduct({ ...item }));
//   };

//   return (
//     <span>
//       <span>
//         <IconButton
//           aria-label='remove'
//           onClick={remove}
//           disabled={cartProd.qty <= 0}
//         >
//           <RemoveIcon />
//         </IconButton>
//         {cartProd.qty}
//         <IconButton aria-label='add' onClick={add}>
//           <AddIcon />
//         </IconButton>
//       </span>
//       <IconButton aria-label='delete' onClick={btnDelete}>
//         <DeleteIcon />
//       </IconButton>
//     </span>
//   );
// }

function SendCart() {
  const cartRaw = useAppSelectorWithTypes(getItemsByCount);
  // const cartProducts = useAppSelectorWithTypes(cartProductsSelectors.products);

  const ids = cartRaw.map((_) => _.item.product_id);
  const { currentData, isLoading } = useGetProductsByIdsQuery(ids);

  const [is1BtnDisabled, setIs1BtnDisabled] = useState<boolean>(false);
  const [data, setData] = useState<any | undefined>(undefined);
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<object | undefined>(undefined);

  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [buyerComment, setBuyerComment] = useState<string>('');

  const USER = 'user';

  useEffect(() => {
    const user = localStorage.getItem(USER);
    if (user) {
      try {
        const objUser = JSON.parse(user);
        objUser?.buyer?.full_name && setFullName(objUser.buyer.full_name);
        objUser?.buyer?.phone && setPhone(objUser.buyer.phone);
        objUser?.buyer?.email && setEmail(objUser.buyer.email);
        // objUser?.buyer_comment &&
        //     setBuyerComment(objUser.buyer_comment);
      } catch (e) {
        console.error(e);
      } finally {
      }
    }
  }, []);

  const saveUserintoLocalStorage = () => {
    const objUser = {
      buyer: {
        full_name: fullName,
        phone: phone,
        email: email,
      },
      // buyer_comment: buyerComment,
    };

    localStorage.setItem(USER, JSON.stringify(objUser));
  };

  const getCartToKeyCRM = () => {
    let cartToKeyCRM = undefined;

    if (currentData) {
      const products: any = [];
      cartRaw.forEach((cartProd) => {
        const product = getProductByProdIdInCart(currentData.data, cartProd);

        if (product) {
          // const copy = { ...product };
          // delete copy.properties_agg;
          // delete copy.danceex_options;
          let sku = product?.sku;
          let comment =
            (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
            product.name;

          if (cartProd.item?.offer_id) {
            const offer = getOfferByProdIdInCart(product, cartProd);
            sku = offer?.sku;
            comment =
              (!sku || sku.trim().length === 0 ? 'Відсутній артикул / ' : '') +
              product.name +
              ': ' +
              getObjListOfferProps({ offer })?.join('/');
          }

          const copy = {
            sku: sku,
            price: product.min_price,
            quantity: cartProd.qty,
            comment: comment,
          };
          products.push(copy);
          // prods.push({ ...copy, quantity: cartProd.qty });
        }
      });

      cartToKeyCRM = {
        // source_id: 1,
        // source_name: 'Instagram Danceex',
        source_id: 5,
        source_name: 'test.danceex.com.ua',
        products,
        buyer: {
          full_name: fullName,
          phone: phone,
          email: email,
        },
        buyer_comment: buyerComment,
      };

      setData(cartToKeyCRM);
    }

    console.log('cartRaws', cartRaw);
    console.log('cartToKeyCRM', cartToKeyCRM);
    return cartToKeyCRM;
  };

  const build = () => {
    if (
      fullName.trim().length == 0 ||
      phone.trim().length == 0
      // ||
      // email.trim().length == 0
    ) {
      alert("Будь-ласка, заповніть поля Ім'я, Телефон та email");
      return;
    }

    if (currentData) {
      const cartToKeyCRM = getCartToKeyCRM();

      if (cartToKeyCRM) {
        handleClick();
      } else {
        console.log('cartToKeyCRM is ', cartToKeyCRM);
        console.log('cartToKeyCRM not send to keyCRM');
      }

      function startLoading() {
        setIs1BtnDisabled(true);
        setLoading(true);
        setData(undefined);
        setError(undefined);
      }

      function handleClick() {
        startLoading();

        const CREATE_ORDER = '/backend/api/keyCRM/create_order.php';

        fetch(CREATE_ORDER, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            order: cartToKeyCRM,
          }),
        })
          .then((res) => {
            return res.text();
          })
          .then((r) => {
            if ('' !== r) {
              const products = JSON.parse(r);
              setData(products);
              setError(undefined);
            }
          })
          .finally(() => {
            setIs1BtnDisabled(false);
            return setLoading(false);
          })
          .catch((_) => setError(_));
      }
    }
  };

  return (
    <div style={{ paddingTop: '40px' }}>
      <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
        <TextField
          label="Ім'я"
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setFullName(e.target.value);
          }}
          value={fullName}
        />
        <TextField
          label='Телефон'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setPhone(e.target.value.trim());
          }}
          value={phone}
        />
        <TextField
          label='email'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setEmail(e.target.value.trim());
          }}
          value={email}
        />
        <TextField
          label='Коментар'
          variant='outlined'
          size='small'
          onChange={(e) => {
            saveUserintoLocalStorage();
            setBuyerComment(e.target.value);
          }}
          value={buyerComment}
          multiline
          rows={3}
          // maxRows={4}
        />
      </div>

      <Button
        variant='outlined'
        onClick={getCartToKeyCRM}
        disabled={is1BtnDisabled}
        style={{ marginTop: '12px' }}
      >
        Показати корзину для відправки
      </Button>
      <Button
        variant='outlined'
        onClick={build}
        disabled={is1BtnDisabled}
        style={{ marginTop: '12px' }}
      >
        Створити замовлення в KeyCRM
      </Button>
      {loading && <CircularProgress color='info' />}
      {isLoading && <CircularProgress color='success' />}
      {data && <pre>{JSON.stringify(data, undefined, 2)}</pre>}
      {error && (
        <pre style={{ color: 'red' }}>
          {JSON.stringify(error, undefined, 2)}
        </pre>
      )}
    </div>
  );
}
