// import 'node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import './react-checkbox-tree.css';

import { useEffect, useState } from 'react';

import CheckboxTree, { Node } from 'react-checkbox-tree';
import { useSearchParams } from 'react-router-dom';

import { getUpdatedFiltersByURL } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/restoreProductsFilterFromUrl';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { useProductFilters } from '@/fsd/pages/ProductsPage/lib/hooks/useProductFilters';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

// https://stackoverflow.com/questions/78094183/react-checkbox-tree-icons-not-shown-properly

interface WidgetReactCheckboxTreeProps {
  filterTypeName: string;
}

export function WidgetReactCheckboxTree(props: WidgetReactCheckboxTreeProps) {
  const { filterTypeName } = props;

  const [checked, setChecked] = useState<Array<string>>([]);
  const [expanded, setExpanded] = useState<Array<string>>([]);
  const [nodes, setNodes] = useState<Array<Node>>([]);
  const [className, setClassName] = useState<string>('isHideRctCollapse');
  const { filtersRTKQ, setFilterRTKQ } = useProductFilters();
  const [searchParam] = useSearchParams();

  useEffect(() => {
    setChecked(getCheckboxFromUrl());
  }, [searchParam]);

  useEffect(() => {
    let _isHideRctCollapse = className;
    const data = filtersRTKQ[filterTypeName].values;
    let root = data.filter((i) => {
      if ('parent_id' in i) {
        _isHideRctCollapse = '';
        return i.parent_id === null;
      } else {
        return true;
      }
    });
    const prepareNodes: Array<Node> = getNodesFromCategories(root, data);

    setClassName(_isHideRctCollapse);
    setNodes(prepareNodes);
  }, [filtersRTKQ]);

  const onCheckHandler = (checked: string[]) => {
    setChecked(checked);
    updateStoreFilters(checked);
  };

  return (
    <div className={className}>
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={(checked) => onCheckHandler(checked)}
        onExpand={(expanded) => setExpanded(expanded)}
        // showExpandAll
        icons={{
          check: <CheckBoxIcon />,
          uncheck: <CheckBoxOutlineBlankIcon />,
          halfCheck: <IndeterminateCheckBoxIcon />,
          expandClose: <ChevronRightIcon />,
          expandOpen: <ExpandMoreIcon />,
          expandAll: <UnfoldMoreIcon />,
          collapseAll: <UnfoldLessIcon />,
        }}
      />
    </div>
  );

  function getCheckboxFromUrl() {
    const changeableFiltersRTKQ = getClone(filtersRTKQ);

    const { filtersRTKQ: filtersRTKQUpdatedByURL } = getUpdatedFiltersByURL(
      changeableFiltersRTKQ,
      searchParam
    );

    let selectedCheckboxFromUrl: string[] = [];
    filtersRTKQUpdatedByURL[filterTypeName].values.forEach((_) => {
      if (_.checked === true) {
        selectedCheckboxFromUrl.push(_.id.toString());
      }
    });
    return selectedCheckboxFromUrl;
  }

  function updateStoreFilters(checked: string[]) {
    const changeableFiltersRTKQ: IFiltersRTKQ = getClone(filtersRTKQ);
    const data = changeableFiltersRTKQ[filterTypeName].values;

    data.forEach((_) => (_.checked = false));

    checked.forEach((_strId) => {
      const checkbox = data.find((_) => _.id === +_strId);
      if (checkbox) {
        checkbox.checked = true;
      }
    });

    setFilterRTKQ({ filtersRTKQ: changeableFiltersRTKQ });
  }
}

function getNodesFromCategories(
  categoriesList: AgeItemType[],
  allCategories: AgeItemType[]
) {
  let _result = categoriesList.map((_) => {
    let node = getNode(_);

    let childrenLevel2 = allCategories.filter((i) => {
      if ('parent_id' in i) {
        return i.parent_id === _.id;
      } else {
        return false;
      }
    });

    if (childrenLevel2.length > 0) {
      node.children = getNodesFromCategories(childrenLevel2, allCategories);
    }

    return node;
  });

  return _result;
}

function getNode(obj: AgeItemType): Node {
  return {
    value: String(obj.id),
    label: obj.title,
  };
}

// const nodes = [
//   {
//     value: 'mars',
//     label: 'Mars',
//     children: [
//       { value: 'phobos', label: 'Phobos' },
//       { value: 'deimos', label: 'Deimos' },
//     ],
//   },
// ];

function getClone(obj: object) {
  return JSON.parse(JSON.stringify(obj));
}
