// @ts-nocheck
import { RootState } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/store';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';

export const getMainMenuRTKQ = (state: RootState) =>
  state.mainMenuReducer.mainMenuRTKQ;

export const getSelectedMenuItem = (state: RootState) =>
  state.mainMenuReducer.selectedMenuItem;

// export const getFiltersForSelectedMenu = (state: RootState) => {
//     const menuItem = state.mainMenuReducer.selectedMenuItem;

//     /**
//          * Example result
//          * filtersForSelectedMenu =
//          * {
//                 "menu_id": 3,
//                 "filters": [
//                     "age",
//                     "gender",
//                     "style"
//                 ],
//                 "checked": false
//             }
//          */
//     let filtersForSelectedMenu = undefined;
//     if (menuItem) {
//         filtersForSelectedMenu =
//             state.mainMenuReducer.mainMenuRTKQ.map_main_menu_to_filters.values.find(
//                 (_) => {
//                     const temp = _ as any;
//                     return temp.menu_id === menuItem.id;
//                 }
//             ) as any; //  | undefined;
//     }

//     return filtersForSelectedMenu;
// };

export const getDynamicFilters = (state: RootState) => {
  const menuItem = state.mainMenuReducer.selectedMenuItem;

  /**
         * Example result
         * filtersForSelectedMenu =
         * {
                "menu_id": 3,
                "filters": [
                    "age",
                    "gender",
                    "style"
                ],
                "checked": false
            }
         */
  // @ts-nocheck
  let filtersForSelectedMenu: IFiltersRTKQ;
  if (menuItem) {
    filtersForSelectedMenu =
      state.mainMenuReducer.mainMenuRTKQ.map_main_menu_to_filters.values.find(
        (_) => {
          const temp = _ as any;
          return temp.menu_id === menuItem.id;
        }
      ) as any; //  | undefined;
  }

  // return filtersForSelectedMenu;

  const filtersRTKQ_0 = state.productsFiltersReducer.filtersRTKQ;
  let dynamicFilters = filtersRTKQ_0;

  // @ts-nocheck
  if (filtersForSelectedMenu) {
    // https://stackoverflow.com/questions/38750705/filter-object-properties-by-key-in-es6
    dynamicFilters =
      // result = ['main_menu__to_delete', 'availability', 'age', 'gender', 'style', 'product_category', 'choose_color', 'choose_size']
      Object.keys(filtersRTKQ_0)
        // result = ['age', 'gender', 'style']
        .filter((key) => filtersForSelectedMenu.filters.includes(key))
        .reduce((obj: IFiltersRTKQ, key) => {
          obj[key] = filtersRTKQ_0[key];
          return obj;
        }, {});
  }

  return dynamicFilters;
};
