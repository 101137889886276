import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { AgeItemTypeMenu } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter/types/AgeItemType';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MainMenuSchema } from '../types/MainMenuSchema';

const initialState: MainMenuSchema = {
    mainMenuRTKQ: {},
    selectedMenuItem: null,
};

export const mainMenuSlice = createSlice({
    name: 'productsFiltersSlice',
    initialState,
    reducers: {
        setMainMenuRTKQ: (state, action: PayloadAction<IFiltersRTKQ>) => {
            state.mainMenuRTKQ = { ...action.payload };
        },
        setSelectedMenuItem: (
            state,
            action: PayloadAction<AgeItemTypeMenu | null>
        ) => {
            let _ = null;
            if (action.payload != null) {
                _ = { ...action.payload };
            }

            state.selectedMenuItem = _;
        },
    },
});

export const { actions: mainMenuActions, reducer: mainMenuReducer } =
    mainMenuSlice;
