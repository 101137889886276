import { useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { __IS_ADMIN_MODE__ } from '@/const';
import {
  useAppDispatch,
} from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import {
  useGetProductsByIdsQuery,
} from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { selectedOfferActions } from '@/fsd/entities/SelectedOffer';
import { selectedProductActions } from '@/fsd/entities/SelectedProduct';
import {
  selectedProductGroupActions,
} from '@/fsd/entities/SelectedProductGroup';

import { ProductNotFound } from '../impl/widgets';
import { RenderPageAsync } from './RenderPage/RenderPage.async';

export const ProductPage = () => {
    // const product = useLoaderData() as TypeProduct | null;
    const { id } = useParams();

    if (id == undefined) return <ProductNotFound />;

    return <Processing id={id} />;
};

function Processing(props: { id: string }) {
    const { id } = props;
    const dispatch = useAppDispatch();
    const { currentData, isLoading, error } = useGetProductsByIdsQuery([+id]);

    useEffect(() => {
        if (currentData) {
            dispatch(
                selectedProductGroupActions.setSelectedProductGroup(
                    currentData.data
                )
            );

            const product = currentData.data[0];
            dispatch(selectedProductActions.setSelectedProduct(product));

            let offer = undefined;
            if (product.danceex_options.offers.length === 1) {
                offer = product.danceex_options.offers[0];
            }
            dispatch(selectedOfferActions.setSelectedOffer(offer));

            if (__IS_ADMIN_MODE__) {
                dispatch(
                    selectedProductGroupActions.setSelectedProductGroupAdmin(
                        currentData.data
                    )
                );

                dispatch(
                    selectedProductActions.setSelectedProductAdmin(product)
                );

                dispatch(selectedOfferActions.setSelectedOfferAdmin(offer));
            }
        }
    }, [currentData]);

    if (isLoading) {
        return (
            <div className='ContentWrapper'>
                <div style={{ fontSize: '24px' }}>
                    Завантаження товару № {id}...
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className='ContentWrapper'>
                <div style={{ fontSize: '24px', color: 'red' }}>
                    Помилка завантаження товару № {id}
                </div>
            </div>
        );
    }

    if (!currentData || currentData.data.length === 0) {
        return <ProductNotFound />;
    }

    return <RenderPageAsync />;
}
