import { RootState } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/config/store';

import { CartProductsSchema } from '../types/CartProductsSchema';

const cartProducts_products = (state: RootState) =>
  (state.cartProductsSliceReducer as CartProductsSchema).products;

const cartProducts_currentProduct = (state: RootState) =>
  (state.cartProductsSliceReducer as CartProductsSchema).currentProduct;

export const cartProductsSelectors = {
  products: cartProducts_products,
  currentProduct: cartProducts_currentProduct,
};
