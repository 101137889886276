import { ThemeOptions } from "@mui/material";

import CommonStyle from "./commonStyle";

export const lightTheme: ThemeOptions = {
  ...CommonStyle,
  palette: {
    mode: "light",
    primary: {
      // main: "#009EAE",
      main: "#000000",
    },
    secondary: {
      main: "#007989",
    },
  },
};
