import './ItemInfo.css';

// ref: https://allfordance.com/contact/
export const ItemInfo = () => {
    return (
        <div className='item__info'>
            <div className='item__info-block'>
                <div className='item__info-title'>Графік роботи:</div>
                <div className='item__info-text'>
                    ПН-ПТ - 14:00-20:30
                    <br />
                    СБ-НД - 11:00-18:00
                    <br />
                    Без обіду
                </div>
            </div>
            <div className='item__info-block'>
                <div className='item__info-title'>Телефон:</div>
                <div className='item__info-text'>
                    <a href='tel:+38073440303' className='item__phone'>
                        +380 (73) 440 03 03
                    </a>
                </div>
            </div>
            {/* <div className='item__info-block'>
                <div className='item__info-title'>м. Театральна</div>
            </div> */}
            {/* <div className='item__about'>Вся інформація про точку →</div> */}
            <div className='item__map'>
                <iframe
                    width={600}
                    height={160}
                    frameBorder={0}
                    style={{ border: 0, maxWidth: '100%' }}
                    src='https://maps.google.com/maps?q=50.51693440873466%2C30.77693257479385&output=embed'
                    allowFullScreen
                />
            </div>
        </div>
    );
};
