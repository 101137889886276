import { Divider } from '@mui/material';

import { FiltersContainer } from '../impl/_ulbi';
import { CardsByRTKQ } from './CardsByRTKQ';
import s from './ProductsPage.module.scss';

export function ProductsPage() {
  return (
    <div className='ContentWrapper'>
      <Divider />
      <div className={s['wrap--aside--products-grid']}>
        <FiltersContainer />
        <CardsByRTKQ />
      </div>
    </div>
  );
}
