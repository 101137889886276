import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { __IS_ADMIN_MODE__ } from '@/const';
import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { productsFiltersActions } from '@/fsd/entities/ProductFilters';
import { getProductFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/selectors/poductsFiltersSelectors';
import {
  getIdsStr,
  useGetRTKQProductsListQuery,
} from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';
import { fromJSON } from '@/fsd/shared/dto/product/fromJSON';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { Cards } from '../impl';

export function CardsByRTKQ() {
  const filtersRTKQ = useSelector(getProductFiltersRTKQ);
  const dispatch = useDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  // const page = parseInt(query.get('page') || '1', 10);

  // const {
  //     data,
  //     isLoading,
  //     error,
  //     isFetching,
  //     // isSuccess, isError, refetch
  // } = useGetRTKQProductsListQuery(
  //     {
  //         filtersRTKQ,
  //         page,
  //         searchParamsString: query.toString(),
  //     } /* { skip: false } */
  // );
  const {
    data,
    isLoading,
    error,
    isFetching,
    // isSuccess, isError, refetch
  } = useGetRTKQProductsListQuery(query.toString());

  useEffect(() => {
    if (typeof data !== 'undefined') {
      if ('choose_size' in filtersRTKQ && 'choose_color' in filtersRTKQ) {
        let jsonV = JSON.parse(JSON.stringify(filtersRTKQ));
        jsonV.choose_size.values = data?.additional_props.choose_size;
        jsonV.choose_color.values = data?.additional_props.choose_color;
        dispatch(productsFiltersActions.setFiltersFromRTKQ(jsonV));
      }
    }
  }, [data]);

  if (isLoading || isFetching) {
    return (
      <div style={{ fontSize: '24px' }}>
        <CircularProgress />
        завантаження товарів...
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ fontSize: '24px', color: 'red' }}>
        Помилка завантаження товарів...
      </div>
    );
  }

  if (data) {
    const products = fromJSON(data.data);

    if (products.length < 1) {
      return (
        <div style={{ fontSize: '20px' }}>
          По Вашому запиту знайдено {products.length} товарів
        </div>
      );
    }

    // const existsProd = products.filter((p) => getOffersQtyByProduct(p) > 0);

    return (
      <div style={{ padding: '12px 0', flexGrow: '1' }}>
        {/* {__IS_ADMIN_MODE__ && (
          <>
            <Typography variant='h6'>{data?.total} товарів</Typography>
            <Typography variant='h6'>
              {products.length} відображено на сторінці
            </Typography>
            <Typography variant='h6'>
              {existsProd.length} товарів в наявності
            </Typography>
          </>
        )} */}

        <Cards
          products={products}
          // products={
          //   __IS_ADMIN_MODE__
          //     ? products
          //     : products.filter((_) => _.quantity > 0)
          // }
        />
        {/* <CustomPagination data={data} /> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <PaginantionRouterIntegration data={data} />
        </div>

        {/* <MUIBasicPagination className={s.MUIBasicPagination} /> */}
      </div>
    );
  }

  return <></>;
}

// function CustomPagination(props: any) {
//     const { data } = props;

//     return (
//         <div style={{ display: 'inline-flex', gap: '6px' }}>
//             {data?.links.map((l, key) => {
//                 return (
//                     <a href={l.url} key={key}>
//                         <span>{l.label}</span>
//                     </a>
//                 );
//             })}
//         </div>
//     );
// }

function PaginantionRouterIntegration(props: any) {
  const { data } = props;

  const dispatch = useAppDispatch();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let page = parseInt(query.get('page') || '1', 10);

  if (page !== data?.current_page) {
    page = data?.current_page || undefined;

    // query.set('page', page.toString());
    // setSearchParams(query);
  }

  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //     console.log('page value', value);
  // };

  return (
    <Pagination
      page={page}
      count={data?.links.length}
      variant='outlined'
      shape='rounded'
      // size='large'
      // onChange={handleChange}
      renderItem={(item) => {
        let to = '';
        if (item.page && data.links.length >= item.page) {
          to = '?' + data.links[item.page - 1].url;
        }

        return (
          <PaginationItem
            component={Link}
            to={to}
            {...item}
            onClick={() => dispatch(productsFiltersActions.setPage(item.page))}
            sx={{ width: '38px', height: '38px' }}
          />
        );
      }}
    />
  );
}

export function buildURLSearchParamsByFiltersRTKQ(filtersRTKQ: IFiltersRTKQ) {
  const urlParamValues: string[] = [];
  const arrEntries = Object.entries(filtersRTKQ);
  arrEntries.forEach((_) => {
    const name = _[0];
    const values = _[1].values;

    const ids = getIdsStr(values);
    if (ids.length > 0) {
      const item = name + '=' + ids;
      urlParamValues.push(item);
    }
  });

  const strUrlParamValues = urlParamValues.join('&');
  return strUrlParamValues;
}
