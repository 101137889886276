// 2do
// Видалити цю функцію.
// Оськільки вона замінена на env.production
export const getBaseUrl = () => {
    let IS_PRODHOST = true;
    const envParam = import.meta.env.VITE_IS_PRODHOST;
    if (envParam === undefined) {
        IS_PRODHOST = true;
    } else {
        if (envParam.toLocaleLowerCase() === 'false') {
            IS_PRODHOST = false;
        }
        if (envParam.toLocaleLowerCase() === 'true') {
            IS_PRODHOST = true;
        }
    }
    const baseUrl = IS_PRODHOST
        ? // ? '/backend/api/danceex'
          //   'https://danceex-laravel.grillhouse.kiev.ua/public/api'
          import.meta.env.VITE_API_BASE_URL
        : '//localhost/api/danceex';

    return baseUrl;
};
