import './MenuMobile.css';

import { Key, useState } from 'react';

import { useGetMainMenuQuery } from '@/fsd/entities/ProductFilters/model/services/fetchProductsList/RTKQProductsListApi';
import { IFiltersRTKQ } from '@/fsd/entities/ProductFilters/model/types/filtersRTKQType';

import { BuildMenu } from '../../../utils/BuildMenu';
import MuiList from './MuiList/MuiList';

// Реалізаці частково скопійована з компоненту FiltersContainer
export const MenuMobile = () => {
  // Отримати дані з Редакс. Для актуальності даних, цей спосіб не використовується. Використовується нижче реквест
  // const filtersRTKQ = useSelector(getMainMenuRTKQ);

  const {
    isLoading,
    isError,
    // isSuccess,
    data: requestedFiltersRTKQ,
  } = useGetMainMenuQuery(null);

  if (isLoading) {
    return <span>Завантаження меню...</span>;
  }

  if (isError) {
    return <span style={{ color: 'red' }}>Помилка завантаження меню</span>;
  }

  if (!requestedFiltersRTKQ) {
    return (
      <span style={{ color: 'red' }}>
        Не вдалось отримати дані для меню із сервера
      </span>
    );
  }

  return <Render requestedFiltersRTKQ={requestedFiltersRTKQ} />;
};

function Render({
  requestedFiltersRTKQ,
}: {
  requestedFiltersRTKQ: IFiltersRTKQ;
}) {
  const [hamburgerOpen, setHamburger] = useState<boolean>(false);

  const btnTxt = hamburgerOpen ? 'Open' : 'Close';

  const menuBuild = BuildMenu.buildRecursiveChild({
    menu: requestedFiltersRTKQ.main_menu.values,
    categories: requestedFiltersRTKQ.categories.values,
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <button onClick={() => setHamburger(!hamburgerOpen)}>
        Mobile Menu {btnTxt}
      </button>
      {hamburgerOpen && (
        <div
          aria-label='Main website navigation'
          className='Header-MenuWrapper'
        >
          <ul className='Menu Menu_isLoaded Menu_isActive_'>
            {menuBuild.map((item: any, idx: Key | null | undefined) => (
              <MuiList item={item} key={idx} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
