import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';

/**
 * 
  menu = [
    {
        "id": 100000,
        "title": "Одяг",
        "link": "/clothes"
    },
    {
        "id": 2,
        "title": "Взуття",
        "link": "/footwear"
    },
  ]

  categories = [
    {
        "id": 20,
        "name": "Накидки",
        "parent_id": null
    },
    {
        "id": 28,
        "name": "Костюм",
        "parent_id": null
    },
    {
        "id": 29,
        "name": "Заколки та резинки для волосся",
        "parent_id": 3
    },
    {
        "id": 30,
        "name": "Декор",
        "parent_id": 3
    },
  ]

 * 
 */

export class BuildMenu {
  static menu: AgeItemType[];
  static categories: AgeItemType[];

  static buildRecursiveChild({
    menu,
    categories,
  }: {
    menu: AgeItemType[];
    categories: AgeItemType[];
  }) {
    this.menu = menu;
    this.categories = categories;

    return this.#build();
  }

  static #build() {
    const withChild = this.menu.map((_) => {
      return {
        ..._,
        child: this.#getChild(_),
      };
    });

    return withChild;
  }

  static #getChild(item: AgeItemType): {
    child: any;
    parent_id: null;
    id: number;
    title: string;
    name?: string;
    checked: boolean;
  }[] {
    const child = this.categories.filter(
      (child) => child.parent_id === item.id
    );

    // child.forEach((_) => ((_ as MenuChildType).child = this.#getChild(_)));
    const addChildToEachItemOfChild = child.map((_) => {
      return {
        ..._,
        child: this.#getChild(_),
      };
    });

    return addChildToEachItemOfChild;
  }
}
