import './Logo.css';

// import DenceexLogo from './denceex_logo_text.webp';
import DenceexLogo from '/images/denceex_logo_text.webp';
import { Link } from 'react-router-dom';

import {
  useProductFilters,
} from '@/fsd/pages/ProductsPage/lib/hooks/useProductFilters';

export const Logo = () => {
    const { clearAllFiltersByKeyRTKQ } = useProductFilters();

    return (
        <>
            <div aria-label='Header logo' className='Header-Logo'>
                <Link to={`/`} onClick={() => clearAllFiltersByKeyRTKQ()}>
                    <img src={DenceexLogo} alt='Header Logo' />
                </Link>
            </div>
        </>
    );
};
