import { useAppDispatch } from '@/fsd/app/providers/ReduxStoreByOfficialDoc/hooks';
import { cartSliceActions } from '@/fsd/entities/cart';
import { ItemsByCountType } from '@/fsd/entities/cart/model/types/CartSchema';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';

export function ActionButtons({ cartProd }: { cartProd: ItemsByCountType }) {
  const dispatch = useAppDispatch();

  const item = {
    product_id: cartProd.item.product_id,
    offer_id: cartProd.item.offer_id,
    color: cartProd.item.color,
  };

  const add = () => {
    dispatch(cartSliceActions.addProductById({ ...item }));
  };
  const remove = () => {
    dispatch(cartSliceActions.reduceProduct({ ...item }));
  };
  const btnDelete = () => {
    dispatch(cartSliceActions.deleteProduct({ ...item }));
  };

  return (
    <span>
      <span>
        <IconButton
          aria-label='remove'
          onClick={remove}
          disabled={cartProd.qty <= 0}
        >
          <RemoveIcon />
        </IconButton>
        {cartProd.qty}
        <IconButton aria-label='add' onClick={add}>
          <AddIcon />
        </IconButton>
      </span>
      <IconButton aria-label='delete' onClick={btnDelete}>
        <DeleteIcon />
      </IconButton>
    </span>
  );
}
