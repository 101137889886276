import { ReactNode } from "react";

import Typography from "@mui/material/Typography/Typography";

interface T {
  children: ReactNode;
}

export const TitleBlock = (props: T) => {
  const { children, ...otherProps } = props;

  return (
    <Typography variant="h6" gutterBottom {...otherProps}>
      {children}
    </Typography>
  );
};
