import { TypeProduct } from '@/fsd/shared/types';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import {
  SelectedProductGroupSchema,
} from '../types/SelectedProductGroupSchema';

const initialState: SelectedProductGroupSchema = {};

export const selectedProductGroup = createSlice({
    name: 'selectedProductGroupSlice',
    initialState,
    reducers: {
        setSelectedProductGroup: (
            state,
            action: PayloadAction<TypeProduct[]>
        ) => {
            state.selectedProductGroup = action.payload;
        },
        setSelectedProductGroupAdmin: (
            state,
            action: PayloadAction<TypeProduct[]>
        ) => {
            state.selectedProductGroupAdmin = action.payload;
        },
    },
});

export const {
    actions: selectedProductGroupActions,
    reducer: selectedProductGroupReducer,
} = selectedProductGroup;
