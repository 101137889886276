import { TypeProduct } from '@/fsd/shared/types';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { IFiltersRTKQ } from '../types/filtersRTKQType';
import { ProductsFiltersSchema } from '../types/poductsFiltersSchema';
import { loadAsyncAgeListLang } from './asyncThunks/loadAsyncAgeListLang';

const initialState: ProductsFiltersSchema = {
    productsRTKQ: [],
    filtersRTKQ: {},
    filtersRTKQAdmin: {},
    // page,
};

export const productsFiltersSlice = createSlice({
    name: 'productsFiltersSlice',
    initialState,
    reducers: {
        setFiltersFromRTKQ: (state, action: PayloadAction<IFiltersRTKQ>) => {
            // const jsonV = JSON.parse(JSON.stringify(action.payload));
            // state.filtersRTKQ = jsonV;

            state.filtersRTKQ = { ...action.payload };

            // let _ = null;
            // if (action.payload != null) {
            //     _ = { ...action.payload };
            // }

            // state.filtersRTKQ = _;
        },
        setFiltersFromRTKQAdmin: (
            state,
            action: PayloadAction<IFiltersRTKQ>
        ) => {
            // const jsonV = JSON.parse(JSON.stringify(action.payload));
            // state.filtersRTKQ = jsonV;

            state.filtersRTKQAdmin = { ...action.payload };

            // let _ = null;
            // if (action.payload != null) {
            //     _ = { ...action.payload };
            // }

            // state.filtersRTKQ = _;
        },
        setProductsRTKQ: (state, action: PayloadAction<TypeProduct[]>) => {
            state.productsRTKQ = action.payload;
        },
        setPage: (state, action: PayloadAction<number | null | undefined>) => {
            state.page = action.payload;
        },
    },
    extraReducers(builder) {
        // builder.addCase(loadAsyncAgeListLang.pending, (_state, _action) => {
        //     console.log('loadAsyncAgeListLang.pending');
        // });
        // builder.addCase(loadAsyncAgeListLang.rejected, (_state, _action) => {
        //     console.log('loadAsyncAgeListLang.rejected');
        // });
        builder.addCase(loadAsyncAgeListLang.fulfilled, (state, action) => {
            console.log('loadAsyncAgeListLang.fulfilled');
            state.filtersRTKQ['age'].values = action.payload;
        });

        // builder.addCase(
        //     callAsyncRTKQProductsList.pending,
        //     (_state, _action) => {
        //         console.log('callAsyncRTKQProductsList.pending');
        //     }
        // );
        // builder.addCase(
        //     callAsyncRTKQProductsList.rejected,
        //     (_state, _action) => {
        //         console.log('callAsyncRTKQProductsList.rejected');
        //     }
        // );
        // builder.addCase(callAsyncRTKQProductsList.fulfilled, () => {
        //     console.log('callAsyncRTKQProductsList.fulfilled');
        // });

        // builder.addMatcher(
        //     // ({ type }) => type === actions.openModal.type,
        //     RTKQProductsListApi.endpoints.getRTKQProductsList.matchFulfilled,
        //     // (state, { payload: { result } }) => {
        //     (state, action) => {
        //         console.log(action.payload);
        //         const filteredProducts = action.payload.data.filter(
        //             (_: any) => {
        //                 if (state.age === AgeEnum.ADULT) return _.quantity < 3;
        //                 if (state.age === AgeEnum.CHILDREN)
        //                     return _.quantity > 34;
        //                 return _.quantity > 34;
        //             }
        //         );
        //         console.log(
        //             'RTKQProductsListApi.endpoints.getFetchProductsList.matchFulfilled'
        //         );
        //         console.log(filteredProducts);
        //         state.productsListAsyncThunk = filteredProducts;
        //     }
        // );
    },
});

export const {
    actions: productsFiltersActions,
    reducer: productsFiltersReducer,
} = productsFiltersSlice;
