import { useNavigate } from 'react-router-dom';

import { MockButtonContained } from '@/fsd/shared/ui/mui/ui';

import { useProductFilters } from '../../../../lib/hooks/useProductFilters';

export const BtnClearFilters = () => {
  const { clearAllFiltersByKeyRTKQ } = useProductFilters();

  const navigate = useNavigate();

  return (
    <MockButtonContained
      name={'Скинути фільтр'}
      onClick={() => {
        clearAllFiltersByKeyRTKQ();
        navigate('/');
      }}
    />
  );
};
