import './Image.css';

import { Link } from 'react-router-dom';
import { TypeProductComponent } from 'src/fsd/shared/types';

export const Image = ({ product }: TypeProductComponent) => {
  return (
    <div className="ProductCard-Image">
      <Link tabIndex={0} to={`/product/${product.id}`}>
        <div className="wrap-imag-box">
          <div className="img-box">
            <img
              className="img-box--image"
              // 300x450
              src={
                product.thumbnail_url
                  ? product.thumbnail_url + "?w=265&h=400&fit=crop&v=1" // fit=crop робить чорну полоску для jpg
                  : // product.thumbnail_url + '?w=5&h=10&v=1' // '?w=5&h=10&fit=crop&v=1'
                    ""
              }
              alt="Мініатюра товару"
              loading="lazy"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};
