import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { __IS_DEV__ } from '@/const';

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // overrides language detection
        lng: 'uk',
        // language to use if translations in user language are not available.
        fallbackLng: false,
        // print to consol debug info: loaded languages, absent keys etc.
        debug: __IS_DEV__ === 'true' ? true : false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        },

        // in case when the languages files loads by node.js or othe backend
        // initImmediate: false,
    });

export default i18n;
