import { TypeProduct } from '@/fsd/shared/types';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SelectedProductSchema } from '../types/SelectedProductSchema';

const initialState: SelectedProductSchema = {
    selectedProduct: undefined,
    selectedProductAdmin: undefined,
};

export const selectedProductSlice = createSlice({
    name: 'selectedProductSlice',
    initialState,
    reducers: {
        setSelectedProduct: (state, action: PayloadAction<TypeProduct>) => {
            state.selectedProduct = action.payload;
        },
        setSelectedProductAdmin: (
            state,
            action: PayloadAction<TypeProduct>
        ) => {
            state.selectedProductAdmin = action.payload;
        },
    },
});

export const {
    actions: selectedProductActions,
    reducer: selectedProductReducer,
} = selectedProductSlice;
