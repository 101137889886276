// import { createHashRouter } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

import App from '@/fsd/app/App';
import { CartPageCustom } from '@/fsd/pages/Cart/CartPageCustom';
import { CartPageDfOriginalAsync } from '@/fsd/pages/Cart/CartPageDfOriginal';
import { ContactsPage } from '@/fsd/pages/ContactsPage';
import { KeyCRMDataPage } from '@/fsd/pages/KeyCRMDataPage';
import { ProductPage } from '@/fsd/pages/ProductPage';
import { ProductsPage } from '@/fsd/pages/ProductsPage';

import { ErrorElement } from './ErrorElement';

export const BuildRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorElement message='App' />,
    children: [
      {
        // path: '',
        index: true,
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/clothes',
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/footwear',
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/accessories',
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/discounts',
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/catalogue',
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: 'product/:id',
        element: <ProductPage />,
        errorElement: <ErrorElement message='ProductPage' />,
      },
      // {
      //     path: 'product_by_react_router/:id',
      //     element: <ProductPage />,
      //     loader: async ({ request, params }) => {
      //         // loaders can be async functions
      //         const res = await fetch(
      //             '/backend/db/keyCRM/products.json',
      //             {
      //                 signal: request.signal,
      //             }
      //         );
      //         const resJson = await res.json();
      //         const products = fromJSON(resJson.data);
      //         const product = products.find((i: TypeProduct) =>
      //             params.id ? i.id === +params.id : undefined
      //         );

      //         return product ? product : null; // loader should return object or null
      //     },
      //     errorElement: <ErrorElement message='ProductPage' />,
      // },
      {
        path: '/keycrm',
        element: <KeyCRMDataPage />,
        errorElement: <ErrorElement message='KeyCRMData' />,
      },
      {
        path: '/cart',
        element: <CartPageDfOriginalAsync />,
        errorElement: <ErrorElement message='CartPageDfOriginalAsync' />,
      },
      {
        path: '/cart-old',
        element: <CartPageCustom />,
        errorElement: <ErrorElement message='CartPageCustom' />,
      },
      {
        path: '/contacts',
        element: <ContactsPage />,
        errorElement: <ErrorElement message='ContactsPage' />,
      },
    ],
  },
]);
