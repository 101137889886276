import { useParams } from 'react-router-dom';

export function ProductNotFound() {
    const { id } = useParams();
    return (
        <div style={{ textAlign: 'center', padding: '50px' }}>
            Немає даних про товар ID = {id} (ProductPage)
        </div>
    );
}
