import '../../../styles/styles.css';

import { AnchorHTMLAttributes, forwardRef } from 'react';

import classNames from 'classnames';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';

export const RadixListItem = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>(({ className, children, title, ...props }, forwardedRef) => (
  <li>
    <NavigationMenu.Link asChild>
      <a
        className={classNames('ListItemLink', className)}
        {...props}
        ref={forwardedRef}
      >
        <div className='ListItemHeading'>{title}</div>
        <p className='ListItemText'>{children}</p>
      </a>
    </NavigationMenu.Link>
  </li>
));
