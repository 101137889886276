import { Fragment } from 'react';

import { Item } from './components';
import { dataItems } from './data';

export const Columns = () => (
    <>
        {dataItems.map((_, idx) => (
            <Fragment key={idx}>
                <Item item={_} />
            </Fragment>
        ))}
    </>
);
