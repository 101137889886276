import * as React from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { useClickMenuItem } from '../../../../../hooks/useClickMenuItem';

export default function NestedList(props: { item: any }) {
  const { item } = props;
  const [open, setOpen] = React.useState(false);
  const { onClickMenuItem } = useClickMenuItem();

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component='nav'
        aria-labelledby='nested-list-subheader'
      >
        <ListItemButton onClick={handleClick}>
          <ListItemText
            primary={item.name}
            onClick={() => onClickMenuItem(item)}
          />
          {item?.child.length > 0 && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
        {item?.child.length > 0 && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              <div style={{ paddingLeft: 16 }}>
                {item?.child.map((_c: any, idx: React.Key) => (
                  <NestedList item={_c} key={idx} />
                ))}
              </div>
            </List>
          </Collapse>
        )}
      </List>
    </>
  );
}
