import { TypeOffer } from '@/fsd/shared/types/scope/TypeProduct';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { SelectedOfferSchema } from '../types/SelectedOfferSchema';

const initialState: SelectedOfferSchema = {
    selectedOffer: undefined,
    selectedOfferAdmin: undefined,
};

export const selectedOfferSlice = createSlice({
    name: 'selectedOffer',
    initialState,
    reducers: {
        setSelectedOffer: (
            state,
            action: PayloadAction<TypeOffer | undefined>
        ) => {
            state.selectedOffer = action.payload;
        },
        setSelectedOfferAdmin: (
            state,
            action: PayloadAction<TypeOffer | undefined>
        ) => {
            state.selectedOfferAdmin = action.payload;
        },
    },
});

export const { actions: selectedOfferActions, reducer: selectedOfferReducer } =
    selectedOfferSlice;
