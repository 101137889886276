import { WidgetReactCheckboxTree } from '@/fsd/pages/ProductsPage/impl/_ulbi/ui/FiltersContainer/WidgetReactCheckboxTree/WidgetReactCheckboxTree';
import { TitleBlock } from '@/fsd/shared/ui/TitleBlock';

interface ListCheckboxes_WidgetReactCheckboxTree {
  title: string;
  filterTypeName: string;
  className?: string;
}

export const ListCheckboxes_WidgetReactCheckboxTree = (
  props: ListCheckboxes_WidgetReactCheckboxTree
) => {
  const { className, title, filterTypeName } = props;

  return (
    <div className={className}>
      <TitleBlock>{title}</TitleBlock>
      <WidgetReactCheckboxTree filterTypeName={filterTypeName} />
    </div>
  );
};
