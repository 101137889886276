import i18n from '@/fsd/shared/i18n/i18n';
import { AgeItemType } from '@/fsd/widgets/ProductsFilters/imlp/AgeFilter';
import { createAsyncThunk } from '@reduxjs/toolkit';

const nameSapce = 'aside_filters/index';
const t = (textCode: string) => i18n.t(nameSapce + ': ' + textCode);

// -> i18n.on ---> use on non-components
// -> useTranslation ---> use on components

interface Props {
    filterValues: AgeItemType[];
}

export const loadAsyncAgeListLang = createAsyncThunk<
    AgeItemType[] /* returned type */,
    Props
>(
    'productsFiltersSlice/loadAsyncAgeListLang',
    async ({ filterValues }, thunkAPI) => {
        // I18nextBrowserLanguageDetector
        // i18n.on('languageChanged', function (lng) {});
        // i18n.on('loaded', function (lng) {
        //     console.log('lng');
        //     console.log(lng);
        // });

        await i18n.loadNamespaces(nameSapce, (w) => console.log(w));

        // i18n.setDefaultNamespace(nameSapce);
        let translatedProps: AgeItemType[] = [];
        try {
            translatedProps = filterValues.map((_) => ({
                ..._,
                title: t(_.title),
            }));
        } catch (e) {
            console.error(e);
            return thunkAPI.rejectWithValue('error');
        }
        // i18n.setDefaultNamespace('translation');

        // thunkAPI.dispatch(productsFiltersActions.setAgeList(AgeList));

        return translatedProps;
    }
);
