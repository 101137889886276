import { Link, useRouteError } from 'react-router-dom';

interface T {
    message: string;
}

export const ErrorElement = ({ message }: T) => {
    const error = useRouteError();
    console.error(error);

    return (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: '8px',
            }}
        >
            <h2 style={{ color: 'red', fontSize: '20px' }}>404</h2>
            <h2 style={{ fontSize: '20px', paddingTop: '8px' }}>
                Сторінка: {message}
            </h2>
            <pre style={{ fontSize: '18px' }}>
                {JSON.stringify(error as object, undefined, 2)}
            </pre>
            <Link to='/'>Перейти на головна у сторінку</Link>
        </div>
    );
};
