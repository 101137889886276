import './ContactsPage.css';

import { Left } from './features/Left/Left';
import { Right } from './features/Right/Right';

export const ContactsPage = () => {
    return (
        <div className='ContentWrapper'>
            <div className='contact-locations'>
                <Left />
                <Right />
            </div>
        </div>
    );
};
