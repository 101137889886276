import { ItemsByCountType } from '@/fsd/entities/cart/model/types/CartSchema';

import { TypeProduct } from '../../types';

export function getOfferByProdIdInCart(
  product: TypeProduct,
  cartProd: ItemsByCountType
) {
  return product.danceex_options.offers.find(
    (o) => o.id === cartProd.item.offer_id
  );
}

export function getProductByProdIdInCart(
  products: TypeProduct[],
  cartProd: ItemsByCountType
) {
  return products.find((p) => {
    let _color = p.danceex_options.color;
    return cartProd.item.product_id === p.id && cartProd.item.color === _color;
  });
}
