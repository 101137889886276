import {
  HeaderBottom,
  HeaderTop,
} from '../impl';

export const Header = () => {
  return (
    <>
      <HeaderTop />
      <HeaderBottom />
    </>
  );
};
