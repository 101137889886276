import './Cards.css';

import { TypeProduct, TypeProductComponent } from 'src/fsd/shared/types';

import { getOffersQtyByProduct } from '@/fsd/shared/lib/product/getOffersQtyByProduct';
import { __DO_NOT_USE__ActionTypes } from '@reduxjs/toolkit';

import { Card } from '../impl/Card';

interface T {
    products: TypeProduct[];
}

export const Cards = ({ products }: T) => {
    if (products === undefined) return <></>;

    return (
        <ul className='CategoryProductList-Page'>
            {products.map((_, idx) => (
                <li key={idx} style={{ position: 'relative' }}>
                    <Mantia product={_} />
                    <Card product={_} />
                </li>
            ))}
        </ul>
    );
};

function Mantia({ product }: TypeProductComponent) {
    const qty = getOffersQtyByProduct(product);

    if (qty === 0) {
        return (
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#b1b1b1a6',
                }}
            ></div>
        );
    }

    return <div></div>;
}
